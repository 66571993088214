.App {
    text-align: center;
}

.App-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
    height: 250px;
    margin: 2em;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

div.mobile__pdf__container>div#viewerContainer {
    position: relative;
    top: 0;
}

.mobile__pdf__container>.pdfViewer {
    position: relative;
}

.video-container {
    position: relative;
}

.video {
    height: 100%;
    width: 100%;
    max-width: 600px;
}

.localVideo {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.remoteVideo {
    width: 100%;
    max-width: 600px;
    position: relative;
}

.localVideo>video {
    width: 100%;
}

.remoteVideo>video {
    width: 100%;
}

video::-webkit-media-controls {
    opacity: 0.01 !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.s3-video-container:focus {
    outline: none;
}

/* Work-around for televisit window displaying behind anything with a z-index > 0 (like the calendar) */
div[role="tooltip"] {
    z-index: 1000 !important;
}