.Button-submit {
    background: linear-gradient(45deg, rgb(0, 59, 61) 30%, rgb(62, 82, 94) 90%) !important;
    border-radius: 3px !important;
    border: 3px solid black !important;
    color: green !important;
    height: 48px !important;
    padding: 0 30px !important;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .30) !important;
    margin: 15px !important;
    font-family: 'Helvetica' !important;
}
  
.Button-back {
    background: linear-gradient(45deg, rgb(106, 151, 140) 30%, rgb(97, 138, 121) 90%) !important;
    border-radius: 3px !important;
    border: 0 !important;
    color: white !important;
    height: 48px !important;
    padding: 0 30px !important;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .30) !important;
    margin: 15px !important;
}
  
.Button-addPatient {
    background: linear-gradient(45deg, rgb(58, 112, 124) 30%, #50cfe9 90%) !important;
    border-radius: 3px !important;
    border: 0 !important;
    color: white !important;
    height: 48px !important;
    padding: 0 30px !important;
    box-shadow: 0 3px 5px 2px rgba(0, 212, 255, .30) !important;
    margin: 15px !important;
}

.Button-changePassword {
    background: linear-gradient(45deg, rgb(68, 104, 119) 30%, rgb(83, 192, 255) 90%) !important;
    border-radius: 3px !important;
    border: 0 !important;
    color: black !important;
    height: 48px !important;
    padding: 0 30px !important;
    box-shadow: 0 3px 5px 2px rgba(255, 0, 51, 0.3) !important;
    margin: 15px !important;
}